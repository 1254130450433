<template>
  <div class="wrapper" id="demoydy">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    ></ctheader>
    <div class="content" ref="ydy">
      <button class="btn primary" @click="reload">reload</button>
      <nmtable
        :thead="thead"
        :tbody="tbody"
        :haveoperation="true"
        :operations="operations"
        :tb-height="tbHeight"
      ></nmtable>
      <pagination
        :start="0"
        :total="20"
        :page-length="10"
        :pageCallback="page"
      ></pagination>
    </div>
    <side :show="false">
      <div></div>
    </side>
  </div>
</template>

<script>
/**
 * @module app/demo/ydy
 * @desc 速商 wurongjian组件例子
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-27
 * @copyright 2021
 */
import { tableResize, localSetItemFail } from "../../modules/utils";
// import side from '@@/side';
// import pagination from '@@/pagination';
// import ctheader from '@@/ctheader';
// import nmtable from '@@/table';
// import router from '@/router';
import side from "../../components/side";
import pagination from "../../components/pagination";
import nmtable from "../../components/table";
import ctheader from "../../components/ctheader";
import router from "../../router";
import * as URL from "../../modules/URLs";
export default {
  name: "demoydy",
  components: { side, pagination, nmtable, ctheader },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      localSetItemFail(vm.pageInfo);
      router.push(URL.LOAD_FAIL);
      vm.tbody = [
        {
          code: 1,
          name: "啊啊啊",
          sex: "男",
        },
        {
          code: 2,
          name: "啊啊啊",
          sex: "男",
        },
        {
          code: 3,
          name: "啊啊啊",
          sex: "男",
        },
        {
          code: 4,
          name: "啊啊啊",
          sex: "男",
        },
      ];
      vm.tResize();
      window.addEventListener("resize", vm.tResize, false);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tResize, false);
  },
  data() {
    return {
      pageInfo: {
        titles: [
          {
            name: "杨丹妍的组件库",
          },
        ],
        backBtn: false,
        backPath: "",
        toPath: URL.DEMO_YDY,
      },
      thead: [
        {
          cls: "pw10",
          name: "编号",
          field: "code",
        },
        {
          cls: "pw10",
          name: "姓名",
          field: "name",
        },
        {
          cls: "pw10",
          name: "性别",
          field: "sex",
        },
      ],
      tbody: [],
      operations: [
        {
          name: "查看权限",
          show: (row) => {
            return true;
          },
          fun: (row) => {
            console.log(row);
          },
        },
        {
          name: "编辑",
          show: (row) => {
            return false;
          },
          fun: (row) => {
            console.log(row);
          },
        },
      ],
      tbHeight: 0,
    };
  },
  computed: {},
  methods: {
    page() {},
    tResize() {
      this.tbHeight = tableResize(
        this.$refs.ydy.clientHeight,
        200,
        this.tbody.length
      );
    },
    reload() {
      this.tbody = [];
      this.tResize();
    },
  },
  mounted() {},
};
</script>

<style lang="less">
@import url("../../less/common.less");
</style>
