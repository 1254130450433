<template>
  <div class="table-scroll">
    <table class="table" width="100%" :style="{ 'min-width': minWidth + 'px' }">
      <thead>
        <tr>
          <th v-for="th in thead" :class="th.cls">{{ th.name }}</th>
          <th v-if="haveoperation">操作</th>
        </tr>
      </thead>
      <tbody :style="{ height: tbHeight + 'px' }">
        <tr v-for="row in tbody">
          <td v-for="td in thead" :class="td.cls">{{ row[td.field] }}</td>
          <td v-if="haveoperation" class="operation">
            <a
              v-for="ope in operations"
              v-if="ope.show(row)"
              @click="ope.fun(row)"
              >{{ ope.name }}</a
            >
          </td>
        </tr>
        <tr v-if="tbody.length === 0">
          <td class="empty">没有相关记录</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * @module components/table
 * @desc 传蔬农贸版 表格
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-27
 * @copyright 2021
 */
export default {
  name: "table",
  props: {
    minWidth: {
      type: Number,
      default: 800,
    },
    thead: {
      type: Array,
      default: [],
    },
    tbody: {
      type: Array,
      default: [],
    },
    haveoperation: {
      type: Boolean,
      default: false,
    },
    operations: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tbHeight: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
