<template>
  <div class="side" :style="{ width: width + 'px' }" v-show="show">
    <div class="s-head">
      <span>{{ title }}</span>
      <i class="sdnm-close" @click="close"></i>
    </div>
    <div class="s-body">
      <slot></slot>
    </div>
    <div class="s-foot" v-if="showFoot">
      <button
        class="btn primary thin"
        @click="save"
        v-if="!notShowSaveBtn && !btnLoad"
      >
        保存
      </button>
      <load-button
        class="btn primary"
        :class="{ thin: !btnLoadDisabled }"
        name="保存"
        :disabled="btnLoadDisabled"
        :callback="saveCallBack"
        v-if="!notShowSaveBtn && btnLoad"
      ></load-button>
      <button class="btn secondary thin" @click="close">取消</button>
    </div>
  </div>
</template>

<script>
/**
 * @module components/side.vue
 * @desc 传蔬-农贸版 右侧侧边弹出
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-27
 * @copyright 2021
 */
export default {
  name: "side",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showFoot: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 460,
    },
    btnLoadDisabled: {
      type: Boolean,
      default: false,
    },
    btnLoad: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "标题",
    },
    notShowSaveBtn: {
      // 不显示保存按钮 默认显示
      type: Boolean,
      default: false,
    },
    closeCallBack: {},
    saveCallBack: {},
  },
  methods: {
    /**
     * 保存
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-27
     */
    save() {
      this.saveCallBack();
    },
    /**
     * 关闭
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-27
     */
    close() {
      this.closeCallBack();
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
